/**
 * @copyright   Dreamover-Studio
 * @author      man.wang@dreamover-studio.com
 * @version     1.0.1
 * @date        2019-08-07 12:00:00
 */
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpProvider} from "./http.provider";
import {NbToastrService} from "@nebular/theme";
import {PagesComponent} from "../../pages/pages.component";
import {Router} from "@angular/router";
import {HttpClient, HttpHeaders, HttpRequest} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    tip: object = {
        "e200": "Success",
        "e400": "Lack of necessary parameters",
        "e403": "Logon is invalid, please log in again",
        "e500": "Server error",
        "e600": "No such mailbox",
        "e601": "Wrong account or password",
        "e602": "Mail Delivery Failed ",
        "e603": "The mailbox already exists",
        "e604": "Invalid verification code",
        "e605": "Two password inconsistencies",
        "e606": "The account already exists ",
        "e607": "Verification code expired",
        "e608": "No such account",
        "e609": "The original password is incorrect",
        "e610": "Please register your account first.",
        "e611": "No such phone",
        "e612": "The phone already exists",
        "e613": "SMS sending failed",
        "e614": "Incorrect mobile number ",
        "e615": "Can’t modify mailbox with this link",
        "e616": "Can’t modify phone with this link",
        "e700": "No privileges.",
        "e701": "The game has started, the team cannot be modified.",
        "e702": "The game is over.",
        "e703": "The game has not started yet.",
        "e704": "invalid operation.",
    };

    constructor(
        public httpProvider: HttpProvider,
        public router: Router,
        public httpClient: HttpClient
    ) {
    }

    getBaseData(url, params?) {
        return this.httpProvider.get(url, {params: params}).then(resp => {
            if (resp.code === 403) {
                this.router.navigateByUrl('/auth/login');
                // return false;
            } else if (resp.code === 200) {
                return resp;
            } else {
                const tip = this.tip['e' + resp.code] ? this.tip['e' + resp.code] : resp.message
                alert(tip);
            }
        }, error => {
            console.log(error, error.code);
        });
    }

    postBaseData(url, config) {
        return this.httpProvider.post(url, {data: config}).then(resp => {
            if (resp.code === 403) {
                this.router.navigateByUrl('/auth/login');
            } else if (resp.code === 200) {
                return resp
            } else {
                const tip = this.tip['e' + resp.code] ? this.tip['e' + resp.code] : resp.message
                alert(tip);
            }
        }, error => {
            // console.log(error,error.code)
            alert('Server error')
        });
    }

    putBaseData(url, config) {
        return this.httpProvider.put(url, {data: config}).then(resp => {
            // console.log('getData', resp);
            if (resp.code === 403) {
                this.router.navigateByUrl('/auth/login');
                return false;
            } else if (resp.code === 200) {
                return resp;
            }
        }, error => {
            // console.log(error,error.code)
            alert('Server error');
        });
    }

    deteleBaseData(url, config) {
        return this.httpProvider.delete(url, {data: config}).then(resp => {
            // console.log('getData', resp);
            if (resp.code === 403) {
                this.router.navigateByUrl('/auth/login');
                return false;
            } else if (resp.code === 200) {
                return resp;
            }
        }, error => {
            // console.log(error,error.code)
            alert('Server error');
        });
    }

    getData(config) {
        return this.httpProvider.post("CmsCommon/select", {data: config}).then(resp => {
            if (resp.code === 403) {
                this.router.navigateByUrl('/auth/login');
                // return false;
            } else if (resp.code === 200) {
                return resp;
            } else {
                const tip = this.tip['e' + resp.code] ? this.tip['e' + resp.code] : resp.message
                alert(tip);
            }
        }, error => {
            console.log(error, error.code);
            // alert('Server error')
        });
    }

    postInsertData(config) {
        return this.httpProvider.post("CmsCommon/insert", {data: config}).then(resp => {
            // console.log('getData', resp);
            if (resp.code === 403) {
                this.router.navigateByUrl('/auth/login');
                return false;
            } else if (resp.code === 200) {
                const tip = this.tip['e' + resp.code] ? this.tip['e' + resp.code] : resp.message
                alert(tip);
                return resp;
            } else {
                const tip = this.tip['e' + resp.code] ? this.tip['e' + resp.code] : resp.message
                alert(tip);
            }
        }, error => {
            // console.log(error,error.code)
            alert('Server error');
        });
    }

    putData(config) {
        return this.httpProvider.put("CmsCommon/update", {data: config}).then(resp => {
            // console.log('getData', resp);
            if (resp.code === 403) {
                this.router.navigateByUrl('/auth/login');
                return false;
            } else if (resp.code === 200) {
                return resp;
            } else {
                const tip = this.tip['e' + resp.code] ? this.tip['e' + resp.code] : resp.message
                alert(tip);
            }
        }, error => {
            // console.log(error,error.code)
            alert('Server error');
        });
    }

    deteleData(config) {
        return this.httpProvider.delete("CmsCommon/delete", {data: config}).then(resp => {
            // console.log('getData', resp);
            if (resp.code === 403) {
                this.router.navigateByUrl('/auth/login');
                return false;
            } else if (resp.code === 200) {
                return resp;
            }
        }, error => {
            // console.log(error,error.code)
            alert('Server error');
        });
    }

    postFile(config) {
        let myheaders = new HttpHeaders();
        myheaders.set("Content-Type", "multipart/form-data");
        let req = new HttpRequest('POST', 'http://asterapi.dreamover-studio.com/cms/api/common/file', config, {
            reportProgress: true
        });

        return this.httpClient.request(req).subscribe(resp => {
            if (resp['code'] === 403) {
                this.router.navigateByUrl('/auth/login');
                return false;
            } else if (resp['code'] === 200) {
                return resp;
            }
        }, (error) => {
            alert('Server error');
        });
    }
}
