import {Injectable} from '@angular/core';
import {NbToastrService} from '@nebular/theme';
import {environment} from '../../../environments/environment';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class GlobalsService {
    public apiUrl = environment.apiUrl;
    public defaultphoto: any = '/upload/player_photo/default.png';
    public defaultfinder: any = window.location.pathname;

    constructor(private toastr: ToastrService) {
    }

    checkDisabled(Data, Schema) {
        let disabled = true;
        const ZSchema = require('z-schema');
        const validator = new ZSchema();
        const dataValidate = validator.validate(Data, Schema);
        const errors = validator.getLastErrors();
        if (!errors) {
            disabled = false;
        } else {
            let message;
            if (errors[0]['params'] && errors[0]['params'][0]) {
                const paramsItem = errors[0]['params'][0];
                if (Schema['properties'][paramsItem]) {
                    message = 'Missing required property: ' + Schema['properties'][paramsItem].title;
                } else {
                    if (errors[0]['title']) {
                        message = errors[0]['title'] + ' ,' + errors[0]['message'];
                    } else {
                        message = errors[0]['message'];
                    }
                }
            } else {
                if (errors[0]['title']) {
                    message = errors[0]['title'] + ' ,' + errors[0]['message'];
                } else {
                    message = errors[0]['message'];
                }
            }
            alert(message);
        }
        return disabled;
    }

    showToastError(mes, title?, status?) {
        this.toastr.error(mes, title, {timeOut: 3000});
    }

    showToastSuccess(mes, title?) {
        this.toastr.success(mes, title, {
            timeOut: 3000,
        });
    }

    showToastWarning(mes, title?) {
        this.toastr.warning(mes, title, {timeOut: 3000});
    }

}
