import {Component, OnInit} from '@angular/core';
import {StringWidget} from "ngx-schema-form";

@Component({
    selector: 'ds-input-selector-widget',
    templateUrl: './input-selector.widget.html',
    styleUrls: ['./input-selector.widget.scss']
})

export class InputSelectorWidget extends StringWidget {
    items: any = [];

    ngModelChange(event) {
        if (event && event.id) {
            this.control.setValue(event.id);
        } else {
            this.control.setValue('');
        }
    }
}
