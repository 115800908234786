import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {
    NbAuthComponent,
    NbLoginComponent,
    NbLogoutComponent,
    NbRegisterComponent,
    NbRequestPasswordComponent,
    NbResetPasswordComponent,
} from '@nebular/auth';
import {AuthGuard} from './auth-guard.service';
import {PagesModule} from '../app/pages/pages.module';
import {DsAuthModule} from '../app/auth/auth.module';

const routes: Routes = [
    {
        path: 'pages',
        // loadChildren: () => import('./pages/pages.module'),
        loadChildren: 'app/pages/pages.module#PagesModule',
        canActivate: [AuthGuard], // here we tell Angular to check the access with our AuthGuard
    },
    {
        path: 'auth',
        // loadChildren: () => DsAuthModule,
        loadChildren: './auth/auth.module#DsAuthModule',
    },
    {path: '', redirectTo: 'pages', pathMatch: 'full'},
    {path: '**', redirectTo: 'pages'},
];

const config: ExtraOptions = {
    useHash: true,
};

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
