import {of as observableOf, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Http} from '@angular/http';

import {HttpProvider} from '../utils/http.provider';
import {Restangular} from 'ngx-restangular';

let counter = 0;

@Injectable()
export class UserService {

    private restObject;

    private users = {
        nick: {name: 'Nick Jones', picture: 'assets/images/nick.png'},
        eva: {name: 'Eva Moor', picture: 'assets/images/eva.png'},
        jack: {name: 'Jack Williams', picture: 'assets/images/jack.png'},
        lee: {name: 'Lee Wong', picture: 'assets/images/lee.png'},
        alan: {name: 'Alan Thompson', picture: 'assets/images/alan.png'},
        kate: {name: 'Kate Martinez', picture: 'assets/images/kate.png'},
    };

    private userArray: any[];

    constructor(
        private http: Http,
        public httpProvider: HttpProvider,
        private restangular: Restangular
    ) {
        // this.userArray = Object.values(this.users);
        this.restObject = this.restangular.all('user');
        // This will query /accounts and return a promise.
    }

    schema(): Observable<any> {
        return observableOf(this.http.get('user/schema'));
    }

    C(): Observable<any> {
        return observableOf(this.http.get('user/insert'));
    }

    R(): Observable<any> {
        // return this.restObject.get('select');
        return observableOf(this.http.get('user/update'));
    }

    U(): Observable<any> {
        return observableOf(this.http.get('user/update'));
    }

    D(): Observable<any> {
        return observableOf(this.http.get('user/delete'));
    }

    getUsers(): Observable<any> {
        return observableOf(this.users);
    }

    getUserArray(): Observable<any[]> {
        return observableOf(this.userArray);
    }

    getUser(): Observable<any> {
        counter = (counter + 1) % this.userArray.length;
        return observableOf(this.userArray[counter]);
    }

    getData(): Observable<any>{
        return observableOf(this.http.get('cms/api/common/select'));
    }
}
