/*
* @copyright   Dreamover-Studio
* @author      man.wang@dreamover-studio.com
* @version     1.0.1
* @date        2020-01-16 16:33:04
*/
import {Component, OnInit, ViewChild} from '@angular/core';
import {StringWidget} from "ngx-schema-form";
import {ImageCroppedEvent} from "ngx-image-cropper";
import {ApiService} from "../../../../@core/utils/api.service";
import {GlobalsService} from "../../../../@core/utils/globals.service";

import {NbWindowService, NbToastrService} from '@nebular/theme';

@Component({
    selector: 'ds-selectImage-widget',
    templateUrl: './selectImage.widget.html',
    styleUrls: ['./selectImage.widget.scss']
})

export class SelectImageWidget extends StringWidget implements OnInit {
    base64String: any;
    imageChangedEvent: any = '';
    croppedImage: any = 'assets/images/no-img.png';
    showCropper: boolean = false;
    defaultValue: boolean = true;
    is_uploadImage: boolean = true;
    aspectRatioX: any = 1;
    aspectRatioY: any = 1;
    resizeToWidth: any = 250;

    ngOnInit() {
        const _ = this;
        if (_.schema['aspectRatioX'])_.aspectRatioX = _.schema['aspectRatioX'];
        if (_.schema['aspectRatioY'])_.aspectRatioY = _.schema['aspectRatioY'];
        if (_.schema['resizeToWidth'])_.resizeToWidth = _.schema['resizeToWidth'];
        this.control.registerOnChange((v, isViewToModel) => {
            if (this.defaultValue && v) {
                _.defaultValue = false;
                this.croppedImage = this.globals.apiUrl + v;
            }
        });

    }

    constructor(private apiServe: ApiService, public globals: GlobalsService, public nbToastrService: NbToastrService) {
        super();
    }

    fileChangeEvent(event: any): void {
        this.defaultValue = false;
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.is_uploadImage = false;
    }

    uploadImage() {
        let formData = {
            type: 'img',
            img: this.croppedImage
        };
        this.apiServe.postBaseData('CmsCommon/file', formData).then(resp => {
            this.nbToastrService.success("Picture selected successfully!");
            this.control.setValue(resp.data.url, {emitEvent: true});
            this.is_uploadImage = true;
            // console.log(resp.data.url, this.control);
        })
    }

    imageLoaded() {
        this.showCropper = true;
        // show cropper
    }

    cropperReady() {
        // cropper ready
    }

    loadImageFailed() {
        // show message
    }
}
