/*
* @Author: Owen Yip
* @Date:   2019-03-24 02:04:20
* @Last Modified by:   Owen Yip
* @Last Modified time: 2019-04-04 00:17:49
*/
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: NbAuthService,
              private router: Router) {
  }

  token: string = "";
  user:any='';

  canActivate() {
    return this.authService.isAuthenticated()
      .pipe(
          tap(authenticated => {
            if (!authenticated) {
              this.router.navigate(['auth/login']);
            } else {
              this.authService.onTokenChange()
              .subscribe((token: NbAuthJWTToken) => {
                if (token.isValid()) {
                    this.user=token.getPayload();
                  this.token = token.getValue(); // here we receive a payload from the token and assigne it to our `user` variable 
                }
              });
            }
          }),
        ); // canActive can return Observable<boolean>, which is exactly what isAuhenticated returns
  }
}
