import {
    Component,
} from '@angular/core';

import {ControlWidget} from 'ngx-schema-form';

@Component({
    selector: 'sf-integer-widget',
    template: `
        <div class="widget form-group">
            <span [attr.for]="id" class="formHelp">
                {{ schema.title }} <i style="color: red">{{schema.description}}</i>
            </span>
            <!--        <span *ngIf="schema.description" class="formHelp">{{schema.description}}</span>-->
            <input [attr.readonly]="schema.readOnly?true:null" [attr.name]="name"
                   [attr.id]="id"
                   class="text-widget integer-widget form-control" [formControl]="control"
                   [attr.type]="'number'" [attr.min]="schema.minimum" [attr.max]="schema.maximum"
                   [attr.placeholder]="schema.placeholder"
                   [attr.maxLength]="schema.maxLength || null"
                   [attr.minLength]="schema.minLength || null">
        </div>`
})
export class IntegerWidget extends ControlWidget {
}
