/*
* @Author: Owen Yip
* @Date:   2019-04-03 03:31:21
* @Last Modified by:   Owen Yip
* @Last Modified time: 2019-04-03 03:32:20
*/
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {map} from 'rxjs/operators/map';

import {NbAuthService, NbAuthJWTToken} from '@nebular/auth';
import {NbAclService, NbRoleProvider} from '@nebular/security';
import {ApiService} from "../@core/utils/api.service";
import {PagesComponent} from "../pages/pages.component";

@Injectable()
export class RoleProvider implements NbRoleProvider {

    constructor(private authService: NbAuthService,
                public apiServe: ApiService,
                private aclService: NbAclService
    ) {

    }

    getRole(): Observable<string> {
        // return this.Roleguard.role();// it returns the role value

        return this.authService.onTokenChange()
            .pipe(
                map((token: NbAuthJWTToken) => {
                    if (token.isValid() && token.getPayload()['roleList']) {
                        let obj={};
                        obj[token.getPayload()['role']]=token.getPayload()['roleList'];
                        this.aclService.setAccessControl(obj);
                    }
                    return token.isValid() ? token.getPayload()['role'] : 'Guest';
                }),
            );
    }
}
