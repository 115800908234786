/**
 *
 * @author    man.wang@dreamover-studio.com
 * @return    array
 * @date        2019-12-24
 */
import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SomeSharedService {
    public globalVar:BehaviorSubject<any[]> = new BehaviorSubject([]);
}