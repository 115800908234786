import {Component, Input, OnInit} from '@angular/core';

import {NbMenuService, NbSidebarService, NbThemeService} from '@nebular/theme';
import {UserService} from '../../../@core/data/users.service';
import {AnalyticsService} from '../../../@core/utils/analytics.service';

import {NbAuthJWTToken, NbAuthService, NbTokenService} from '@nebular/auth';
import {ApiService} from "../../../@core/utils/api.service";
import {Router, NavigationEnd} from "@angular/router";
import {AuthGuard} from "../../../auth-guard.service";
import {SomeSharedService} from '../../../@core/data/global.service';

@Component({
    selector: 'ngx-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

    @Input() position = 'normal';

    user: any;

    userTest = {};

    userMenu = [{title: 'Profile'}, {title: 'Log out'}];

    showScore: boolean = false;
    scoreInfo: any;

    constructor(private sidebarService: NbSidebarService,
                private menuService: NbMenuService,
                private userService: UserService,
                private analyticsService: AnalyticsService,
                private themeService: NbThemeService,
                private authService: NbAuthService,
                public apiServe: ApiService,
                public router: Router,
                public authGuard: AuthGuard,
                private tokenService: NbTokenService,
                private someSharedService: SomeSharedService) {

        const _ = this;

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (event.url === "/pages/liveGames/enter") {
                    _.showScore = true;
                    _.getScoreInfo();
                } else {
                    _.showScore = false
                }
            }
        })

        // this.authService.onTokenChange()
        //     .subscribe((token: NbAuthJWTToken) => {
        //         console.log(token.isValid(), token);
        //         if (token.isValid()) {
        //             this.user = token.getPayload(); // here we receive a payload from the token and assigne it to our `user` variable
        //         }
        //
        //     });
    }

    ngOnInit() {
        this.themeService.changeTheme('default');
        this.someSharedService.globalVar.subscribe(d => {
            if (Number(d[0])) {
                this.getScoreInfo(d);
                this.showScore = true;
            } else {
                this.getScoreInfo();
            }
        })
    }

    toggleSidebar(): boolean {
        this.sidebarService.toggle(true, 'menu-sidebar');

        return false;
    }

    toggleSettings(): boolean {
        this.sidebarService.toggle(false, 'settings-sidebar');

        return false;
    }

    goToHome() {
        this.menuService.navigateHome();
    }

    startSearch() {
        this.analyticsService.trackEvent('startSearch');
    }

    logout() {
        this.tokenService.clear();
        this.router.navigate(['/auth/login'])
    }

    /**
     * thisIsFunction
     * 请求比赛数据
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date        2019-12-24
     */
    getScoreInfo(ID?) {
        const gameInfo = localStorage.getItem('gameInfo');
        if (ID) {
            const params = {
                game_id: ID[0],
                homeTeam_id: ID[1],
                visitingTeam_id: ID[2],
            };
            this.apiServe.getBaseData('CmsGame/gameInfo', params).then(resp => {
                if (resp !== undefined) {
                    this.scoreInfo = resp.data;
                }
            })
        } else {
            if (gameInfo) {
                const gameInfoJSON = JSON.parse(gameInfo);
                const params = {
                    game_id: gameInfoJSON.game_id,
                };
                this.apiServe.getBaseData('CmsGame/gameInfo', params).then(resp => {
                    if (resp != undefined) {
                        this.scoreInfo = resp.data;
                    }
                })
            }
        }
    }

}
