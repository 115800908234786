/*
* @Author: Owen Yip
* @Date:   2019-03-24 13:32:49
* @Last Modified by:   Owen Yip
* @Last Modified time: 2019-03-25 00:12:39
*  Instruction to create custom widget
*  https://github.com/guillotinaweb/ngx-schema-form
*
*  Firstly, create a folder with custom widget name
*  Then go to registry create widget
*  Then go to theme.module register the widget
*/
import {DefaultWidgetRegistry} from "ngx-schema-form";
import {InputSelectorWidget} from './input-selector/input-selector.widget';
import {RichtextWidget} from "./richtext/richtext.widget";
import {NgxCropperWidget} from "./ngx-cropper/ngx-cropper.widget";
import {DatepickerWidget} from "./datepicker/datepicker.widget";
import {StringWidget} from "./string/string.widget";
import {CheckboxWidget} from "./checkbok/checkbok.widget";
import {IntegerWidget} from "./integer/integer.widget";
import {RadioWidget} from "./radio/radio.widget";
import {SelectWidget} from "./select/select.widget";
import {SelectSpecialWidget} from "./select-special/select-special.widget";
import {TextAreaWidget} from "./textarea/textarea.widget";
import {DatetimeWidget} from "./datetime/datetime.widget";
import {SelectImageWidget} from "./selectImage/selectImage.widget";

export class SchemaFormWidgetsRegistry extends DefaultWidgetRegistry {
    constructor() {
        super();

        this.register('ds-input-selector', InputSelectorWidget);
        this.register('ds-richtext', RichtextWidget);
        this.register('ds-ngx-cropper', NgxCropperWidget);
        this.register('ds-datepicker', DatepickerWidget);
        this.register('ds-datetime', DatetimeWidget);
        this.register('string', StringWidget);
        this.register('select', SelectWidget);
        this.register('ds-select-special', SelectSpecialWidget);
        this.register('ds-select-image', SelectImageWidget);
        this.register('textarea', TextAreaWidget);
        this.register('checkbox', CheckboxWidget);
        this.register('radio', RadioWidget);
        this.register('number', IntegerWidget);
    }
}
