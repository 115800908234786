import {Component} from '@angular/core';
import {ControlWidget} from 'ngx-schema-form';

@Component({
    selector: 'sf-radio-widget',
    template: `
        <div class="widget form-group">
            <!--	<label>{{schema.title}}</label>-->
            <span *ngIf="schema.title" class="formHelp">{{schema.title}} <i
                    style="color: red">{{schema.description}}</i></span>
            <div *ngFor="let option of schema.oneOf" class="radio">
                <label class="horizontal control-label">
                    <input [formControl]="control" [attr.name]="name" [attr.id]="id + '.' + option.enum[0]"
                           value="{{option.enum[0]}}" type="radio" [disabled]="schema.readOnly||option.readOnly">
                    {{option.description}}
                </label>
            </div>
            <input *ngIf="schema.readOnly" [attr.name]="name" type="hidden" [formControl]="control">
        </div>`
})
export class RadioWidget extends ControlWidget {
}
