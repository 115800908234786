/*
* @copyright   Dreamover-Studio
* @author      man.wang@dreamover-studio.com
* @version     1.0.1
* @date        2020-01-16 16:33:04
*/
import {Component, OnInit, ViewChild} from '@angular/core';
import {StringWidget} from 'ngx-schema-form';
import {FlatpickrOptions} from 'ng2-flatpickr'; // for dateClick
import {DatePipe} from '@angular/common';

@Component({
    selector: 'ds-datetime-widget',
    templateUrl: './datetime.widget.html',
    styleUrls: ['./datetime.widget.scss'],
})

export class DatetimeWidget extends StringWidget implements OnInit {
    dateFormat: string = 'Y-m-d H:i';
    enableTime: boolean = true;
    dateType: string = 'datetime'; //date、time、datetime
    DateTimeOptions: FlatpickrOptions;
    placeholder: string = 'Select time';
    value: string = '';
    defaultValue: boolean = true;

    constructor(private datePipe: DatePipe) {
        super();
    }

    ngOnInit() {
        const _ = this;
        this.placeholder = _.schema['placeholder'] ? _.schema['placeholder'] : 'Select time';
        if (_.schema['dateType']) {
            this.dateType = _.schema['dateType'];
            if (this.dateType === 'date') {
                _.dateFormat = 'Y-m-d';
                _.enableTime = false;
            } else if (this.dateType === 'time') {
                _.dateFormat = 'H:i';
                _.enableTime = false;
            }
        }
        _.DateTimeOptions = {
            enableTime: _.enableTime,
            dateFormat: _.dateFormat,
            onChange: function (val) {
                _.defaultValue = false;
                const value = _.formatDate(val[0]);
                _.control.setValue(value, {emitEvent: false});
            },
        };
        _.control.registerOnChange((v, isViewToModel) => {
            if (_.defaultValue && v) {
                _.value = _.formatDate(v);
                _.defaultValue = false;
                _.control.setValue(_.value);
            }
        });

    }

    formatDate(date) {
        let format = 'yyyy-MM-dd HH:mm', value = '';
        if (this.dateType === 'date' || this.dateType === 'time') {
            format = 'yyyy-MM-dd';
        }
        if (this.dateType === 'time') {
            format = 'HH:mm';
        }
        const datestr = JSON.stringify(new Date(date));
        if (datestr === 'Invalid Date') {
            value = '';
        } else {
            value = this.datePipe.transform(date, format);
        }
        return value;
    }
}
