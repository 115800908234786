/**
* @copyright   2016-2019 Dreamover-Studio
* @description 'ngx-bootstrap datepicker plugin widget for shema'
* @author      jianhui.huang@dreamover-studio.com
* @version     0.0.1
* @date        2019-04-29
*/
import { Component, OnInit } from '@angular/core';
import { StringWidget } from "ngx-schema-form";

@Component({
    selector: 'datepicker',
    templateUrl: './datepicker.widget.html',
    styleUrls: ['./datepicker.widget.scss']
})
export class DatepickerWidget extends StringWidget implements OnInit {

    constructor(
        ) { 
        super()
    }

    ngOnInit() {
    }

}
