/*
* @Author: Owen Yip
* @Date:   2019-04-03 05:34:16
* @Last Modified by:   Owen Yip
* @Last Modified time: 2019-04-03 05:59:14
*/
import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';

 /** A router wrapper, adding extra functions. */
@Injectable()
export class DsRouterExtService {

  private previousUrl: Array<string> = [];
  private currentUrl: string = undefined;
  private backing: boolean = false;

  constructor(private router : Router) {
    this.router.events
      .pipe(filter((e: any) => e instanceof RoutesRecognized),
          pairwise()
      ).subscribe((e: any) => {
          if(!this.backing)
            this.previousUrl.push(e[0].urlAfterRedirects);
          else
            this.backing = false;
      });
  }

  public getPreviousUrl(){
    return this.previousUrl;
  }

  public goBack(){
    this.backing = true;
    if(this.previousUrl.length)
      this.router.navigateByUrl(this.previousUrl.pop());
  }

  public exist(){
    if(this.previousUrl.length)
      return true;
    else
      return false;
  }
}
