/**
* @copyright   2016-2019 Dreamover-Studio
* @description 'ngx-gallery plugin'
* @author      jianhui.huang@dreamover-studio.com
* @version     0.0.1
* @date        2019-04-28
*/
/* Firstly, create a folder with custom component name
*  Then go to index.ts import component
*  Then go to theme.module register the component
*  */
import { Component, OnInit, Input } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';

@Component({
    selector: 'gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
    @Input() options : NgxGalleryOptions[];
    @Input() images : NgxGalleryImage[];
    galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[];
    constructor() { 
        this.galleryOptions = [
            {
                width: '500px',
                height: '200px',
                thumbnailsColumns: 2,
                imageAnimation: NgxGalleryAnimation.Slide,
                imageSwipe : true,
                thumbnailsSwipe : true,
                image : false,
                imageSize : "Contain",
                thumbnailsRemainingCount : true,
                imageArrows: false,
                thumbnailsArrows: false,
                previewSwipe: true
            },
            // max-width 800
            {
                breakpoint: 800,
                width: '100%',
                height: '600px',
                imagePercent: 80,
                thumbnailsPercent: 20,
                thumbnailsMargin: 20,
                thumbnailMargin: 20,
                imageSwipe : true,
                thumbnailsSwipe : true,
            },
            // max-width 400
            {
                breakpoint: 400,
                preview: false,
                imageSwipe : true,
                thumbnailsSwipe : true,
            }
        ];

        
    }

    ngOnInit() {
        this.galleryImages = this.images;
        if(this.options) this.galleryOptions = this.options;
    }

}
