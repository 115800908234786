/**
* @copyright   2016-2019 Dreamover-Studio
* @description 'image-cropper plugin widget for shema'
* @author      jianhui.huang@dreamover-studio.com
* @version     0.0.1
* @date        2019-04-28
*/
import { Component, OnInit } from '@angular/core';
import { StringWidget } from "ngx-schema-form";
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NbWindowService, NbToastrService } from '@nebular/theme';
@Component({
    selector: 'ds-ngx-cropper-widget',
    templateUrl: './ngx-cropper.widget.html',
    styleUrls: ['./ngx-cropper.widget.scss']
})
export class NgxCropperWidget extends StringWidget implements OnInit {
    imageChangedEvent: any = '';
    croppedImageList: Array<any> = [];
    imageCropper : any = "";
    croppedImage : any = "";
    imageFileChageEnable : boolean = false;
    resizeToWidth : number = 300;
    cropperMinWidth : number = 300;
    imageLoading : boolean = false;
    constructor(
        public nbWindowService : NbWindowService,
        public nbToastrService : NbToastrService
        ) { 
        super();
    }

    ngOnInit() {
    }

    fileChangeEvent(event: any): void {
        this.imageLoading = true;
        this.imageChangedEvent = event;
        let image : any = event.target.files[0];
        let fr = new FileReader();
            fr.onload = () => { // when file has loaded
            var img = new Image();
            let src : any = fr.result;
            img.src = src; // The data URL
            img.onload = () => {
                let image_height = img.height;
                let image_width = img.width;
                if(image_height < 300 || image_width < 300){
                    this.nbToastrService.danger("图片宽高不能小于300");
                    this.imageChangedEvent = "";
                    this.croppedImage = "";
                    this.imageFileChageEnable = false;
                    this.imageCropper = "";
                }
            };
        };

        fr.readAsDataURL(image);
    }
   
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }
    imageLoaded(event) {
        // show cropper
        this.imageFileChageEnable = true;
        this.imageLoading = false;
    }
    cropperReady() {
        // cropper ready
    }
    loadImageFailed() {
        // show message
    }

}
