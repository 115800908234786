import {Component, OnInit} from '@angular/core'
import {ControlWidget} from 'ngx-schema-form'

@Component({
    selector: 'ds-text',
    template: `
        <div class="widget form-group">
          <span *ngIf="schema.title" class="formHelp">
            {{ schema.title }} <i style="color: red">{{schema.description}}</i>
          </span>
            <div>
                <label class="control-label">{{control.value}}</label>
            </div>
        </div>`
})
export class TextWidget extends ControlWidget implements OnInit {
    ngOnInit() {
        // console.log(this.control);
    }
}
