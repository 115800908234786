import { Component } from '@angular/core'
import { ControlWidget} from 'ngx-schema-form'

@Component({
  selector: 'sf-string-widget',
  template: `<input *ngIf="this.schema.widget.id ==='hidden'; else notHiddenFieldBlock"
  [attr.name]="name" type="hidden" [formControl]="control">
<ng-template #notHiddenFieldBlock>
<div class="widget form-group">
    <span [attr.for]="id" class="horizontal control-label formHelp">
      {{ schema.title }} <i style="color: red">{{schema.description}}</i>
    </span>
    <input [name]="name" [attr.readonly]="(schema.widget.id!=='color') && schema.readOnly?true:null"
    class="text-widget.id textline-widget form-control"
    [attr.type]="!this.schema.widget.id || this.schema.widget.id === 'string' ? 'text' : this.schema.widget.id"
    [attr.id]="id"  [formControl]="control" [attr.placeholder]="schema.placeholder"
    [attr.maxLength]="schema.maxLength || null"
    [attr.minLength]="schema.minLength || null"
    [attr.required]="schema.isRequired || null"
    [attr.disabled]="(schema.widget.id=='color' && schema.readOnly)?true:null">
    <input *ngIf="(schema.widget.id==='color' && schema.readOnly)" [attr.name]="name" type="hidden" [formControl]="control">
</div>
<ng-container *ngIf="control.dirty">
    <div *ngFor="let error of errorMessages" class="form-group" style="color:red;">{{error}}</div>
    </ng-container>
</ng-template>`
})
export class StringWidget extends ControlWidget{
  getInputType() {
    if (!this.schema.widget.id || this.schema.widget.id === 'string') {
      return 'text';
    } else {
      return this.schema.widget.id;
    }
    
  }
}
