/*
* @Author: Owen Yip
* @Date:   2019-03-24 05:06:03
* @Last Modified by:   Owen Yip
* @Last Modified time: 2019-03-29 01:40:27
*/
import {Injectable} from '@angular/core';
import {Http, RequestOptions, Headers, Response} from '@angular/http';
import {HttpHeaders} from '@angular/common/http';
// import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import {Observable} from 'rxjs';
import {Router} from "@angular/router";
import {NbAuthService} from '@nebular/auth';
import {GlobalsService} from './globals.service';

export interface XRequestOptions {
    params?: any;
    data?: any;
}

@Injectable()
export class HttpProvider {
    sessionId: string = '';
    apiPrefix = '';

    static searchParamsFormat(searchParams: any): string {
        if (typeof searchParams === 'string') {
            return searchParams;
        }
        if (typeof searchParams === 'object') {
            let arr = [];
            for (let key in searchParams) {
                if (searchParams.hasOwnProperty(key) && searchParams[key] !== undefined && searchParams[key] !== null) {
                    arr.push(key + '=' + encodeURIComponent(searchParams[key]));
                }
            }
            return arr.join('&');
        }
        return '';
    }

    constructor(private http: Http, private authService: NbAuthService, public router: Router, public globals: GlobalsService) {
        /**
         * 获取登入token
         * @author    man.wang@dreamover-studio.com
         * @return    array
         * @date        2018-09-10
         */
        this.authService.onTokenChange()
            .subscribe((token) => {
                this.sessionId = token.getValue();
            });
        this.apiPrefix = this.globals.apiUrl + '/api/';
    }

    get(url: string, options: XRequestOptions = {}): Promise<any> {
        const api = this.joinApi(url, options);
        const result: Observable<Response> = this.http.get(api, this.getRequestOptions());
        return this.responseHandle(result);
    }

    //create
    post(url: string, options: XRequestOptions = {}): Promise<any> {
        const api = this.joinApi(url, options);
        const result: Observable<Response> = this.http.post(api, options.data, this.getRequestOptions());

        return this.responseHandle(result);
    }

    //update
    put(url: string, options: XRequestOptions = {}): Promise<any> {
        const api = this.joinApi(url, options);
        const result: Observable<Response> = this.http.put(api, options.data, this.getRequestOptions());
        return this.responseHandle(result);
    }

    //delete
    delete(url: string, options: XRequestOptions = {}): Promise<any> {
        const api = this.joinApi(url, options);
        const result: Observable<Response> = this.http.delete(api, this.getRequestOptions(options.data));
        return this.responseHandle(result);
    }

    upload(url: string, options: XRequestOptions = {}): Promise<any> {
        const api = this.joinApi(url, options);
        const result: Observable<Response> = this.http.post(api, options.data);
        return this.responseHandle(result);
    }

    publishSessionId(id: string) {
        this.sessionId = id;
    }

    private joinApi(url: string, options?: XRequestOptions): string {

        let requestUrl = this.apiPrefix + url;
        // console.log(requestUrl);
        // let requestUrl = url;
        if (!options) {
            options = {
                params: {
                    // t: Date.now()
                }
            };
        } else {
            if (options.params) {
                // options.params.t = Date.now();
            } else {
                options.params = {
                    // t: Date.now()
                };
            }
        }
        const requestParams = HttpProvider.searchParamsFormat(options.params);
        if (requestUrl.indexOf('?') === -1) {
            let questionMark = /\\?$/.test(requestUrl) ? '?' : '';
            return requestUrl + questionMark + requestParams;
        }
        return requestUrl + '&' + requestParams;
    }

    private getRequestOptions(body?: any): RequestOptions {
        let headers: any = new Headers({
            'Content-type': 'application/json; charset=UTF-8',
            // 'Content-type': 'application/x-www-form-urlencoded'
            'Accept': 'application/json',
            // 'ds-auth-api': 'hubbis+dreamover'
        });
        if (this.sessionId) {
            headers = new Headers({
                'Content-type': 'application/json; charset=UTF-8',
                // 'Content-type': 'application/x-www-form-urlencoded'
                'Accept': 'application/json',
                // 'ds-auth-api': 'hubbis+dreamover',
                // 'token': this.sessionId
                'Authorization': this.sessionId
            });
        }
        return new RequestOptions({
            headers: headers,
            withCredentials: false,
            body
        });
    }

    private responseHandle(response: Observable<Response>): Promise<any> {
        return new Promise((resolve, reject) => {
            return response.toPromise().then(response => {
                if (response && response.status) {
                    if (response.status === 200) {
                        let result = response.json();
                        // if (!result.success && result.message === 'NO_LOGIN') {
                        //     if(!skipLoginPop) this.permissionsService.publishLoginState(false);
                        // }
                        resolve(result);
                    } else {
                        reject(response.text());
                    }
                }
            }, error => {
                reject(error);
            });
        });
    }
}
