/*
* @copyright   Dreamover-Studio
* @author      man.wang@dreamover-studio.com
* @version     1.0.1
* @date        2019-09-16 16:33:04
*/
import {Component, OnInit, ViewChild} from '@angular/core';
import {StringWidget} from "ngx-schema-form";
import { GlobalsService } from "../../../../@core/utils/globals.service";



@Component({
    selector: 'ds-richtext-widget',
    templateUrl: './richtext.widget.html',
    styleUrls: ['./richtext.widget.scss']
})

export class RichtextWidget extends StringWidget implements OnInit {

    constructor(public globals: GlobalsService) {
        super();
       
    }

    ckeConfig = {
        allowedContent: false,
        forcePasteAsPlainText: true,
        extraPlugins: 'autogrow',
        autoGrow_minHeight: 300,
        autoGrow_maxHeight: 600,
        language: 'en-us',
        image_previewText:'',
        //filebrowserImageUploadUrl: this.globals.apiUrl+'/api/CmsCommon/uploadImage',
        // filebrowserBrowseUrl:this.globals.apiUrl+'/api/CmsCommon/uploadImage',
        filebrowserBrowseUrl: this.globals.defaultfinder+'assets/ckfinder/ckfinder.html',
        filebrowserImageBrowseUrl: this.globals.defaultfinder+'assets/ckfinder/ckfinder.html?type=Images',
        filebrowserUploadUrl: this.globals.defaultfinder+'assets/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Files',
        filebrowserImageUploadUrl: this.globals.defaultfinder+'assets/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images'
        // 图片上传 
        // filebrowserImageUploadUrl :`https://wechat.dreamover-studio.cn/web/index.php?store_id=2&r=api/default/upload-image`,
    };
    mycontent: string;
    log: string = '';

    ngOnInit() {
        // console.log(this.control);

        let config = GlobalsService
    }

    onChange(mode) {
        this.control.setValue(mode);
    }
}
