/**
 * @copyright   2016-2019 Dreamover-Studio
 * @description 'common service'
 * @author      jianhui.huang@dreamover-studio.com
 * @version     0.0.1
 * @date        2019-05-09
 */
import {of as observableOf, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Http} from '@angular/http';

import {HttpProvider} from '../utils/http.provider';
import {HttpRequest, HttpClient, HttpEventType, HttpResponse, HttpHeaders, HttpParams} from '@angular/common/http';

@Injectable()
export class CommonService {
    public apiPrefix = 'https://asterapi.dreamover-studio.com';

    constructor(
        private http: Http,
        public httpClient: HttpClient,
    ) {
    }

    schema(): Observable<any> {
        return observableOf(this.http.get('user/schema'));
    }

    /**
     * @copyright   2016-2019 Dreamover-Studio
     * @description '上传base64 image'
     * @author      jianhui.huang@dreamover-studio.com
     * @version     0.0.1
     * @date        2019-05-10
     */
    uploadBaseImage(url?, config?): Observable<any> {
        let headers = new HttpHeaders({
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        });
        const request = new HttpRequest("POST", url, config,
            {
                headers: headers, reportProgress: true
            });
        return this.httpClient.request(request);
    }
}
