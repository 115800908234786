import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {
    NbActionsModule,
    NbCardModule,
    NbLayoutModule,
    NbMenuModule,
    NbRouteTabsetModule,
    NbSearchModule,
    NbSidebarModule,
    NbTabsetModule,
    NbThemeModule,
    NbUserModule,
    NbCheckboxModule,
    NbPopoverModule,
    NbContextMenuModule,
    NbProgressBarModule,
    NbCalendarModule,
    NbCalendarRangeModule,
    NbStepperModule,
    NbButtonModule,
    NbInputModule,
    NbAccordionModule,
    NbDialogModule,
    NbWindowModule,
    NbListModule,
    NbToastrModule,
    NbAlertModule,
    NbSpinnerModule,
    NbRadioModule,
    NbSelectModule,
    NbTooltipModule,
    NbDatepickerModule,
} from '@nebular/theme';

import {NbSecurityModule} from '@nebular/security';

import {
    DsDatatableComponent,
    FooterComponent,
    HeaderComponent,
    SearchInputComponent,
    ThemeSettingsComponent,
    SwitcherComponent,
    LayoutDirectionSwitcherComponent,
    ThemeSwitcherComponent,
    ThemeSwitcherListComponent,
    GalleryComponent
} from './components';
import {
    CapitalizePipe,
    PluralPipe,
    RoundPipe,
    TimingPipe,
    NumberWithCommasPipe,
    EvaIconsPipe,
    CeilPipe
} from './pipes';
import {
    OneColumnLayoutComponent,
    SampleLayoutComponent,
    ThreeColumnsLayoutComponent,
    TwoColumnsLayoutComponent,
} from './layouts';
import {DEFAULT_THEME} from './styles/theme.default';
import {COSMIC_THEME} from './styles/theme.cosmic';
import {CORPORATE_THEME} from './styles/theme.corporate';

// Schema forms
import {
    WidgetRegistry,
    DefaultWidgetRegistry,
    SchemaValidatorFactory,
    ZSchemaValidatorFactory,
    SchemaFormModule
} from 'ngx-schema-form';
import {SchemaFormWidgetsRegistry} from './components/schema-form-widgets/schema-form-widgets.registry';

// Schema form - input selector
import {InputSelectorWidget} from './components/schema-form-widgets/input-selector/input-selector.widget';
import {NgSelectModule} from '@ng-select/ng-select';

// Schema form - selectImage
import {SelectImageWidget} from './components/schema-form-widgets/selectImage/selectImage.widget';

// Schema form - richtext
import {RichtextWidget} from './components/schema-form-widgets/richtext/richtext.widget';
import {CKEditorModule} from 'ng2-ckeditor';

// Schema form - datetime
import {DatetimeWidget} from './components/schema-form-widgets/datetime/datetime.widget';

// Schema form - text
import {TextWidget} from './components/schema-form-widgets/text/text.widget';

// Schema form - ngx-cropper
import {NgxCropperWidget} from './components/schema-form-widgets/ngx-cropper/ngx-cropper.widget';
import {ImageCropperModule} from 'ngx-image-cropper';


// Schema form - datepicker
import {DatepickerWidget} from './components/schema-form-widgets/datepicker/datepicker.widget';
import {Ng2FlatpickrModule} from 'ng2-flatpickr';

// Schema form - StringWidget
import {StringWidget} from './components/schema-form-widgets/string/string.widget';

// Schema form - TextAreaWidget
import {TextAreaWidget} from './components/schema-form-widgets/textarea/textarea.widget';
// Schema form - SelectWidget
import {SelectWidget} from './components/schema-form-widgets/select/select.widget';

// Schema form - CheckboxWidget
import { CheckboxWidget} from './components/schema-form-widgets/checkbok/checkbok.widget';
// Schema form - RadioWidget
import { RadioWidget} from './components/schema-form-widgets/radio/radio.widget';
// Schema form - IntegerWidget
import { IntegerWidget} from './components/schema-form-widgets/integer/integer.widget';
// Schema form - SelectSpecialWidget
import {SelectSpecialWidget} from "./components/schema-form-widgets/select-special/select-special.widget";

// ngx skelton loader
import {ContentLoaderModule} from '@netbasal/ngx-content-loader';

// ngx datatable
import {NgxDatatableModule} from '@swimlane/ngx-datatable';

// ngx gallery
import {NgxGalleryModule} from 'ngx-gallery';


import {TabsModule, TabsetConfig, BsDatepickerModule, TimepickerModule} from 'ngx-bootstrap';

const WIDGETS = [InputSelectorWidget, RichtextWidget, TextWidget, NgxCropperWidget,SelectSpecialWidget, DatepickerWidget, StringWidget, TextAreaWidget, SelectWidget,IntegerWidget,CheckboxWidget,RadioWidget, DatetimeWidget, SelectImageWidget];

const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule, NgSelectModule, CKEditorModule, ContentLoaderModule, NgxDatatableModule, ImageCropperModule, NgxGalleryModule, TabsModule, SchemaFormModule,Ng2FlatpickrModule];

const NB_MODULES = [
    NbCardModule,
    NbLayoutModule,
    NbTabsetModule,
    NbRouteTabsetModule,
    NbMenuModule,
    NbUserModule,
    NbActionsModule,
    NbSearchModule,
    NbSidebarModule,
    NbCheckboxModule,
    NbPopoverModule,
    NbContextMenuModule,
    NgbModule,
    NbSecurityModule, // *nbIsGranted directive,
    NbProgressBarModule,
    NbCalendarModule,
    NbCalendarRangeModule,
    NbStepperModule,
    NbButtonModule,
    NbListModule,
    NbToastrModule,
    NbInputModule,
    NbAccordionModule,
    NbDialogModule,
    NbWindowModule,
    NbAlertModule,
    NbSpinnerModule,
    NbRadioModule,
    NbSelectModule,
    NbTooltipModule,
];

const COMPONENTS = [
    SwitcherComponent,
    LayoutDirectionSwitcherComponent,
    ThemeSwitcherComponent,
    ThemeSwitcherListComponent,
    DsDatatableComponent,
    HeaderComponent,
    FooterComponent,
    SearchInputComponent,
    ThemeSettingsComponent,
    OneColumnLayoutComponent,
    SampleLayoutComponent,
    ThreeColumnsLayoutComponent,
    TwoColumnsLayoutComponent,
    GalleryComponent
];

const ENTRY_COMPONENTS = [
    ThemeSwitcherListComponent,
];

const PIPES = [
    CapitalizePipe,
    PluralPipe,
    RoundPipe,
    TimingPipe,
    NumberWithCommasPipe,
    EvaIconsPipe,
    CeilPipe
];

const NB_THEME_PROVIDERS = [
    ...NbThemeModule.forRoot(
        {
            name: 'default',
        },
        [DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME],
    ).providers,
    ...NbSidebarModule.forRoot().providers,
    ...NbMenuModule.forRoot().providers,
    ...NbDialogModule.forRoot().providers,
    ...NbWindowModule.forRoot().providers,
    ...NbToastrModule.forRoot().providers,
];

@NgModule({
    imports: [...BASE_MODULES, ...NB_MODULES, BsDatepickerModule.forRoot(), NbDatepickerModule.forRoot(), TimepickerModule.forRoot()],
    exports: [...BASE_MODULES, ...NB_MODULES, ...COMPONENTS, ...PIPES],
    declarations: [...COMPONENTS, ...PIPES, ...WIDGETS],
    entryComponents: [...ENTRY_COMPONENTS, ...WIDGETS],
})
export class ThemeModule {
    static forRoot(): ModuleWithProviders {
        return <ModuleWithProviders>{
            ngModule: ThemeModule,
            providers: [
                ...NB_THEME_PROVIDERS,
                {
                    provide: WidgetRegistry,
                    useClass: SchemaFormWidgetsRegistry
                },
                {
                    provide: SchemaValidatorFactory,
                    useClass: ZSchemaValidatorFactory
                },
                TabsetConfig,
            ],
        };
    }
}
