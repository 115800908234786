import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Router, NavigationEnd} from "@angular/router";

@Component({
    selector: 'ds-datatable',
    styleUrls: ['./ds-datatable.component.scss'],
    templateUrl: './ds-datatable.component.html',
})
export class DsDatatableComponent implements OnInit {
    limit: number = 25;
    temp: Array<any> = [];

    @Input() selected: Array<any> = [];
    @Input() rows: Array<any> = [];
    @Input() columns: Array<any> = [];
    @Input() numToPage: number = 1;
    @Input() btnEdit: boolean = false;
    @Input() hasAction: boolean = true;
    @Input() btnGame: boolean = false;
    @Input() offsetNum: number = 0;
    @Output() valueChange = new EventEmitter();
    @Output() gameChange = new EventEmitter();
    @Output() rowChange = new EventEmitter();

    constructor(public router: Router) {


    }

    ngOnInit() {
    }

    editData(row) {
        this.valueChange.emit(row);
    }

    //game list 专属函数
    changeGame(row) {
        this.gameChange.emit(row);
    }

    /**
     * thisIsFunction
     * 跳转表格页数
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date      2019-09-29
     */
    changePager(num, type?) {
        if (type) {
            let keycode = window.event ? num.keyCode : num.which;
            if (keycode == 13) {//回车键
                this.offsetNum = Number(this.numToPage) - 1
            }
        } else {
            if (Number(num)) {
                this.offsetNum = Number(num) - 1
            }
        }

    }

    /**
     * @copyright   2016-2019 Dreamover-Studio
     * @description '列表選中item后，觸發select
     * @author      jianhui.huang@dreamover-studio.com
     * @version     0.0.1
     * @date        2019-04-25
     */
    onSelect({selected}) {
        this.selected.splice(0, this.selected.length);
        this.selected.push(...selected);
    }

    changeOffsetNum() {
        this.offsetNum = 0;
    }

    /**
     * thisIsFunction
     * 删除表格数据
     *
     * @author    man.wang@dreamover-studio.com
     * @return    array
     * @date      2019-09-29
     */
    trash(){
        let count = 0;
        this.selected.forEach((val, key) => {
            let temp = [...this.rows];
            let index = temp.indexOf(val);
            temp.splice(index, 1);
            this.rows = temp;
            if (++count >= this.selected.length) {
                this.selected = [];
            }
        });
    }
}
