import { Component } from '@angular/core';

import { ControlWidget } from 'ngx-schema-form';

@Component({
  selector: 'sf-textarea-widget',
  template: `<div class="widget form-group">
    <span [attr.for]="id" class="horizontal control-label formHelp">
      {{ schema.title }} <i style="color: red">{{schema.description}}</i>
    </span>
    <textarea [readonly]="schema.readOnly" [name]="name"
        class="text-widget textarea-widget form-control"
        [attr.placeholder]="schema.placeholder"
        [attr.maxLength]="schema.maxLength || null"
    [attr.minLength]="schema.minLength || null"
        [formControl]="control"></textarea>
        
</div>
<ng-container *ngIf="control.dirty">
    <div *ngFor="let error of errorMessages" class="form-group" style="color:red;">{{error}}</div>
    </ng-container>`
})
export class TextAreaWidget extends ControlWidget {}
